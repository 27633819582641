import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

const ImageFetcher = ({
  image_name,
  altText = "Image",
  style = {},
  sx = {},
  component = "img", // Default to <img>, switchable to "box"
}) => {
  const url =`https://dyn44mn2nsz7o.cloudfront.net/images/${image_name}`
  // const [imageData, setImageData] = useState("");

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://gl41ssqofd.execute-api.ap-south-1.amazonaws.com/prod/image/${image_name}`,
  //         {
  //           method: "GET",
  //           // mode: "cors", // Explicitly enable CORS
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-api-key":"nFKaVkGMv48A8LmeaHHFG3uAxmRxaiAo7ErD2BVL",
  //           },
  //         }
  //       );
  //       const data = await response.json();
  //       // //console.log("🚀 ~ fetchImage ~ data:", data)
  //       setImageData(data.image); // Assume API returns a Base64 string in `data.image`
  //     } catch (error) {
  //       console.error("Error fetching the image:", error);
  //     }
  //   };

  //   fetchImage();
  // }, [image_name]);

  const imageSource = image_name ? url : "";


  if (component === "box") {
    return (
      <Box
        component="img"
        src={imageSource}
        alt={altText}
        sx={sx}
      />
    );
  }

  return (
    <img
      src={imageSource}
      alt={altText}
      style={style}
    />
  );
};


export default ImageFetcher;
